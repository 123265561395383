import muduko from '../../assets/images/partners/muduko.png'
import foksal from '../../assets/images/partners/GWF_nowe logo.png'
import luckDuckGames from '../../assets/images/partners/LuckyDuckGames.png'
import Rebel from '../../assets/images/partners/rebel.png'
import bezPradu from '../../assets/images/partners/grybezpradu_logo.png'
import LucrumGames from '../../assets/images/partners/LucrumGames.png'
import Tefl from '../../assets/images/partners/trefl.png'
import UnplugedGames from '../../assets/images/partners/unpluged games.png'
import Egmont from '../../assets/images/partners/EGMONT_LOGO_SCREEN-002.png'
import Dragon from '../../assets/images/partners/dragon.png'
import pocztaPlanszowkowa from '../../assets/images/partners/pocztaPlanszowkowa.svg'
import radioPlanszowki from '../../assets/images/partners/radioPlanszowki.png'
import guslarz from '../../assets/images/partners/guslarz.jpg'
import EskaKoszalin from '../../assets/images/partners/logo-eska.svg'
import gk24 from '../../assets/images/partners/gk24.jpg'
import Radio from '../../assets/images/partners/radio.png'
import MmTrendyKoszalin from '../../assets/images/partners/mmtrendy.svg'
import tygodnikKoszalinskiMiasto from '../../assets/images/partners/tygodnikMiastoKoszalin.jpg'
import koszalinCentrumPomorza from '../../assets/images/partners/koszalinCentrumPomorza.png'
import szprychaCafe from '../../assets/images/partners/szprychaCafe.jpg'
import fotogrfGajdamowicz from '../../assets/images/partners/fotografGajdamowicz.jpg'
import bezpieczenstwa from '../../assets/images/partners/bezpieczenstwa.png'
import domMilosierdzia from '../../assets/images/partners/domMilosierdzia.svg'
import naSkarpie from '../../assets/images/partners/naSkarpie.png'
import kociaKawiarnia from '../../assets/images/partners/kociaKawiarnia.jpg'
import cafeMondo from '../../assets/images/partners/cafeMondo.png'
import uno from '../../assets/images/partners/uno logo-01.png'
import krowa from '../../assets/images/partners/krowa.jpg'
import aleeeKawa from '../../assets/images/partners/aleeeKawa.png'
import akm from '../../assets/images/partners/akm.png'
import barberianInk from '../../assets/images/partners/barberianInk.png'
import cliniq from '../../assets/images/partners/cliniq.png'
import wyszkoleni from '../../assets/images/partners/wyszkoleni.png'
import yoursite from '../../assets/images/partners/yourSite.png'
import bam from '../../assets/images/partners/bam.png'
import comicsClub from '../../assets/images/partners/comics club.png'
import rehactive from '../../assets/images/partners/rehactive.png'
import medical from '../../assets/images/partners/medical.png'
import complexFinance from '../../assets/images/partners/complexFinance.svg'
import taro from '../../assets/images/partners/taro.png'
import tattoo from '../../assets/images/partners/majki.png'
import Mzk from '../../assets/images/partners/mzk.png'
import koszalinInfo from '../../assets/images/partners/koszalinInfo.png'
import tvMax from '../../assets/images/partners/tvMax.jpg'
import ck105 from '../../assets/images/partners/ck105.webp'
import centrumInformacjiTurystycznej from '../../assets/images/partners/centrumInformacjiKlturalnejITurystycznej.webp'
import radaOsSrodmiescie from '../../assets/images/partners/radaOsidlaŚródmieście.png'
import Biblioteka from '../../assets/images/partners/biblioteka.png'
import Kultura from '../../assets/images/partners/kultura.png'
import muzeum from '../../assets/images/partners/muzeum.jpg'
import Unia from '../../assets/images/partners/unia.png'
import Mwmk from '../../assets/images/partners/mwmk.png'
import Raduszka from '../../assets/images/partners/raduszka.png'
import PracowniaPozarzadowa from '../../assets/images/partners/pracownia.svg'
import Green from '../../assets/images/partners/green.jpg'
import chylki from '../../assets/images/partners/chyłki.jpg'
import cus from '../../assets/images/partners/cus.png'
import hufiec from '../../assets/images/partners/hufiec.png'
import razemRazniej from '../../assets/images/partners/razemRazniej.png'
import Aqua from '../../assets/images/partners/aqua.png'
import Baltyk from '../../assets/images/partners/bałtyk.png'
import Kadruk from '../../assets/images/partners/kadruk.jpg'
import MalowanieSwiatlem from '../../assets/images/partners/malowanieSwiatlem.png'
import Zabawkarski from '../../assets/images/partners/zabawkarski.png'
import PalacMlodziezy from '../../assets/images/partners/palacMlodziezy.jpg'
import lifesurfers from '../../assets/images/partners/lifesurfers.png'
import emka from '../../assets/images/partners/emka.png'
import zdolneDzieciaki from '../../assets/images/partners/zdolneDzieciaki.png'
import wydawnictwoGaj from '../../assets/images/partners/gaj.jpg'
import Prezentowo from '../../assets/images/partners/prezentowo.png'
import LuxuryButique from '../../assets/images/partners/luxurybutique.svg'
import osiedleCyrkonii from '../../assets/images/partners/osiedleCyrkonii.png'
import smlSzkoła from '../../assets/images/partners/smlSzkołaJęzykowa.png'
import smlTlumaczenia from '../../assets/images/partners/smlTlumaczenia.png'
import pemiskin from '../../assets/images/partners/pemiskin.jpg'
import bmp from '../../assets/images/partners/bmp.png'
import pokaLogo from '../../assets/images/partners/pokaLogo.png'
import skvot from '../../assets/images/partners/skvot.png'
import laFemme from '../../assets/images/partners/laFemme.png'
import wesolePierniki from '../../assets/images/partners/wesolePierniki.png'
import foxGames from '../../assets/images/partners/336652280_927274705365805_2932918403008501965_n.jpg'
import portal from '../../assets/images/partners/logo_portalgames_wektorowe_kolor-1.svg'
import strategosGames from '../../assets/images/partners/Strategos Games.svg'
import playmaty from '../../assets/images/partners/playmaty.webp'
import blackMonk from '../../assets/images/partners/Logo_Black_Monk_na-ciemne.png'
import zKostkaIMiarka from '../../assets/images/partners/348744322_1780338422386403_3765196210933196174_n [Converted].png'
import kolegobrzeg from '../../assets/images/partners/kolegobrzeg.png'
import klubyGierPlanszowych from '../../assets/images/partners/klubyGierPlanszowych.png'

export default {
  publisherHouses: [
    {
      company: 'Portal Games',
      url: 'https://sklep.portalgames.pl',
      logo: portal,
      discountNumber: 0.5,
      discount: '50% zniżki na planszówki',
      descriptionDiscount: 'Zniżka na planszówki',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Dawid Wienchor',
          position: 'Dział Sprzedaży Sales Department',
          phone: '577 644 911',
          email: 'dawid@portalgames.pl',
        },
      ],
    },
    {
      company: 'Lucky Duck Games',
      url: 'https://luckyduckgames.com',
      logo: luckDuckGames,
      discountNumber: 0.5,
      discount: '50% na planszówki bez nowości',
      descriptionDiscount: 'Zniżka na planszówki',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Zofia Bocheńska',
          position: 'Marketing Specialist',
          phone: '',
          email: 'zofia@luckyduckgames.com',
        }
      ],
    },
    {
      company: 'Muduko',
      url: 'https://sklep.muduko.com',
      logo: muduko,
      discountNumber: 0.2,
      discount: '20% na kod KOSZALIN2023 bez promocji',
      descriptionDiscount: 'Zniżka na planszówki',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Anna Grzesiak',
          position: 'Marketing Manager',
          phone: '539 942 686',
          email: 'a.grzesiak@muduko.com',
        },
        {
          name: 'Paula',
          position: 'Sales Manager / Card&Games',
          phone: '600 515 284',
          email: 'paula@muduko.com',
        },
      ],
    },
    {
      company: 'Fox Games',
      url: 'https://foxgames.pl',
      logo: foxGames,
      discountNumber: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Katarzyna Rymarczyk',
          position: 'Dział Promocji',
          phone: '',
          email: 'katarzyna.rymarczyk@gwfoksal.pl',
        }
      ],
    },
    {
      company: 'Foksal Grupa Wydawnicza',
      url: 'https://www.gwfoksal.pl',
      logo: foksal,
      discountNumber: 0.05,
      discount: 'Na gry i gry książkowe z kodem planszowkikoszalin',
      descriptionDiscount: 'Zniżka 5%',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Katarzyna Rymarczyk',
          position: 'Dział Promocji',
          phone: '',
          email: 'katarzyna.rymarczyk@gwfoksal.pl',
        }
      ],
    },
    {
      company: 'Rebel',
      url: 'https://rebel.pl',
      logo: Rebel,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Beniamin Zaremba',
          position: 'Młodszy specjalista ds. marketingu',
          phone: '',
          email: 'beniamin.zaremba@rebel.pl',
        }
      ],
    },
    {
      company: 'Gry bez prądu',
      url: 'https://grybezpradu.eu',
      logo: bezPradu,
      discountNumber: 0.04,
      discount: '4% kod planszówkikoszalin',
      descriptionDiscount: 'Zniżka na planszówki',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Łukasz Kuć',
          position: 'Właściciel',
          phone: '530 727 565',
          email: 'lukasz@gamesunplugged.eu',
        }
      ],
    },
    {
      company: 'Unpluged Games',
      url: 'https://grybezpradu.eu',
      logo: UnplugedGames,
      discountNumber: 0.04,
      discount: '4% kod planszówkikoszalin',
      descriptionDiscount: 'Zniżka na planszówki',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Łukasz Kuć',
          position: 'Właściciel',
          phone: '530 727 565',
          email: 'lukasz@gamesunplugged.eu',
        }
      ],
    },
    {
      company: 'Lucrum Games',
      url: 'https://lucrumgames.pl',
      logo: LucrumGames,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Magdalena Włodarczyk',
          position: 'Project Manager',
          phone: '',
          email: 'magdalena.wlodarczyk@lucrumgames.pl',
        }
      ],
    },
    {
      company: 'Trefl',
      url: 'https://www.trefl.com',
      logo: Tefl,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Paulina Miłoszewska',
          position: 'Koordynator Biura Zarządu',
          phone: '519 304 500',
          email: 'p.miloszewska@trefl.com',
        }
      ],
    },
    {
      company: 'Egmont',
      url: 'https://egmont.pl',
      logo: Egmont,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Beata Sawoń',
          position: 'Specjalista ds. marketingu',
          phone: '501 693 371',
          email: 'beata.sawon@egmont.pl',
        }
      ],
    },
    {
      company: 'Black Monk',
      url: 'https://egmont.pl',
      logo: blackMonk,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Patrycja Olchowy',
          position: 'Marketing Witch',
          phone: '535 335 530',
          email: 'patrycja.olchowy@blackmonk.pl',
        }
      ],
    },
    {
      company: 'Play maty',
      url: 'https://playmaty.pl',
      logo: playmaty,
      discount: '20% zniżki na produkty',
      descriptionDiscount: 'Zniżka na produkty',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Dixie',
          position: '',
          phone: '',
          email: 'marketing@playmaty.pl',
        }
      ],
    },
    {
      company: 'DragonEye',
      url: 'https://dragoneye.pl',
      logo: Dragon,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: '',
          position: '',
          phone: '',
          email: '',
        }
      ],
    },
    {
      company: 'Z Kostką i Miarką',
      url: 'https://dragoneye.pl',
      logo: zKostkaIMiarka,
      discount: 'https://www.facebook.com/zkostkaimiarka',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Konrad Rzadkiewicz',
          position: '',
          phone: '',
          email: 'zkostkaimiarka@gmail.com',
        }
      ],
    },
    {
      company: 'Poczta Planszówkowa',
      url: 'https://pocztaplanszowkowa.pl',
      logo: pocztaPlanszowkowa,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Tomek Międzik',
          position: 'Założyciel',
          phone: '661 614 333',
          email: 'tomasz.miedzik@pocztaplanszowkowa.pl',
        },
        {
          name: 'Biuro',
          position: '',
          phone: '',
          email: 'kontakt@pocztaplanszowkowa.pl',
        },
      ],
    },
    {
      company: 'Radio Planszówki',
      url: 'https://www.youtube.com/channel/UCal4r5fTSv3WQiDRal4zeig',
      logo: radioPlanszowki,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: '',
          position: '',
          phone: '',
          email: '',
        }
      ],
    },
    {
      company: 'Jaźniarz',
      url: 'https://www.facebook.com/groups/4436918606427362',
      logo: guslarz,
      businessCards: false,
      posters: false,
      discountNumber: 0.1,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na książkę',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Szymon Skorupski',
          position: 'Założyciel',
          phone: '533 802 670',
          email: 'szymon-skorupski1@wp.pl',
        },
        {
          name: 'Kamil Skorupski',
          position: 'Założyciel',
          phone: '792 586 686',
          email: 'upskymail@gmail.com',
        },
      ],
    },
    {
      company: 'Wydwnictwo Gaj',
      url: 'https://wydawnictwogaj.pl/',
      logo: wydawnictwoGaj,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Karol Przybylak',
          position: 'Właściciel',
          phone: '508 808 789',
          email: 'info@ekomedia.com.pl',
        }
      ],
    },
    {
      company: 'Strategos Games',
      url: 'https://www.facebook.com/StrategosGames',
      logo: strategosGames,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Marcin Wisthal',
          position: 'Strategos Games',
          phone: '',
          email: 'contact@strategosgames.com',
        }
      ],
    },
    {
      company: 'SKVOT',
      url: 'https://skvot.pl/?utm_source=page&utm_medium=pr&utm_campaign=main_skvot&utm_term_planszowki_koszalin',
      logo: skvot,
      discount: '15% zniżki przy skorzystaniu z <a href="https://skvot.pl/course/369-projektowanie-gier-planszowych?utm_source=page&utm_medium=pr&utm_campaign=main_skvot&utm_term_planszowki_koszalin15" target="_blank">link</a>',
      descriptionDiscount: 'Zniżka na kurs tworzenia gier planszowych',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Maja Lewandowska',
          position: 'Właściciel',
          phone: '697 171 694',
          email: 'maja.lewandowska@l-a-b-a.pl',
        }
      ],
    },
  ],
  mediaPartners: [
    {
      company: 'Radio Eska Koszalin',
      url: 'https://www.eska.pl/koszalin',
      logo: EskaKoszalin,
      contacts: [
        {
          name: 'Karina Mozolewska',
          position: 'Redaktor naczelny',
          phone: '94 346 24 51',
          email: 'koszalin@eska.pl',
        }
      ],
    },
    {
      company: 'Głos Koszaliński',
      url: 'https://gk24.pl',
      logo: gk24,
      contacts: [
        {
          name: 'Przemysław Szymańczyk',
          position: 'Redaktor Naczelny',
          phone: '607 287 664',
          email: 'przemyslaw.szymanczyk@polskapress.pl',
        },
        {
          name: 'Marcin Stefanowski',
          position: 'Zastępca Redaktora',
          phone: '697 770 227',
          email: 'marcin.stefanowski@gk24.pl',
        },
      ],
    },
    {
      company: 'Polskie Radio Koszalin',
      url: 'https://prk24.pl',
      logo: Radio,
      contacts: [
        {
          name: 'Kasia Chybowska',
          position: 'Redaktor Naczelny',
          phone: '697 770 106',
          email: 'k.chybowska@radio.koszalin.pl',
        }
      ],
    },
    {
      company: 'Gazeta MM Trendy Koszalin',
      url: 'https://www.facebook.com/mmtrendykoszalin',
      logo: MmTrendyKoszalin,
      contacts: [
        {
          name: 'Ewelina Żuberek',
          position: 'Dziennikarz',
          phone: '94 347 35 42',
          email: 'ewelina.zuberek@polskapress.pl',
        }
      ],
    },
    {
      company: 'Gazeta Miasto Tygodnik Koszaliński',
      url: 'http://miasto.koszalin.pl',
      logo: tygodnikKoszalinskiMiasto,
      contacts: [
        {
          name: 'Monika Kwaśniewska',
          position: 'Redaktor Naczelny',
          phone: '505 898 359',
          email: '',
        },
        {
          name: 'Redakcja',
          position: 'redakcja@miasto.koszalin.pl',
          phone: '94 720 24 02',
          email: 'm.kwasniewska@miasto.koszalin.pl',
          email2: 'monika.kwasnieska01@gmail.com',
        }
      ],
    },
    {
      company: 'Koszalin Centrum Pomorza',
      url: 'https://centrumpomorza.pl',
      logo: koszalinCentrumPomorza,
      contacts: [
        {
          name: 'Magdalena Golik',
          position: 'Referat Promocji i Nowych Mediów',
          phone: '59 307 00 06',
          email: 'magdalena.golik@um.koszalin.pl',
        }
      ],
    },
    {
      company: 'Koszalin Info',
      url: 'https://koszalininfo.pl',
      logo: koszalinInfo,
      contacts: [
        {
          name: 'Marek Łagodzki',
          position: 'Redaktor Naczelny',
          phone: '669 990 639',
          email: 'marek.lagocki@koszalininfo.pl',
        },
        {
          name: 'Redakcja',
          position: '',
          phone: '',
          email: 'redakcja@koszalininfo.pl',
        },
      ],
    },
    {
      company: 'TV Max',
      url: 'http://tvmax.pl',
      logo: tvMax,
      contacts: [
        {
          name: 'Monika Kuzio-Czapka',
          position: 'Redaktor Naczelny',
          phone: '505 100 956',
          email: 'monika.kuzio@tvmax.pl',
        },
        {
          name: 'Mateusz Prus',
          position: 'Dziennikarz',
          phone: '691 813 803',
          email: 'redakcja@tvmax.pl',
        },
        {
          name: 'Sekretariat',
          position: '',
          phone: '94 720 23 33',
          email: 'sekretariat@tkk.net.pl',
        },
      ],
    },
  ],
  localCompanies: [
    {
      company: 'Your site',
      url: 'https://your-site.pl/shop?discount=PROMO15',
      logo: yoursite,
      businessCards: false,
      posters: false,
      discount: '15% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na wszystkie usługi',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Jan Szymański',
          position: 'Właściciel',
          phone: '509 609 439',
          email: 'kontakt@your-site.pl',
        },
      ],
    },
    {
      company: 'Fotograf Gajdamowicz',
      url: 'https://gajdamowicz.art',
      logo: fotogrfGajdamowicz,
      businessCards: true,
      posters: false,
      discount: '5 zł / usługę - na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na wszystkie usługi',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Rafał Gajdamowicz',
          position: 'Właściciel',
          phone: '723 421 062',
          email: 'studio@gajdamowicz.art ',
        },
      ],
    },
    {
      company: 'Wyższa Szkoła Bezpieczeństwa',
      url: 'https://www.wsb.net.pl',
      logo: bezpieczenstwa,
      businessCards: true,
      posters: true,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Bartosz Jamniak',
          position: 'Dziekan',
          phone: '692 211 709',
          email: '',
        },
        {
          name: 'Dziekanat',
          position: '',
          phone: '94 354 18 31',
          email: '',
        },
      ],
    },
    {
      company: 'Szprycha Cafe',
      url: 'https://www.facebook.com/szprychacafekoszalin',
      logo: szprychaCafe,
      businessCards: true,
      posters: false,
      discount: '1 zł taniej na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na wszystkie kawy',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: '',
          position: 'Właściciel',
          phone: '500 330 117',
          email: '',
        },
      ],
    },
    {
      company: 'Dom Miłosiedzia',
      url: 'https://dommilosierdzia.pl',
      logo: domMilosierdzia,
      businessCards: false,
      posters: true,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: '',
          position: '',
          phone: '660 539 514',
          email: 'poczta@dommilosierdzia.pl',
        },
      ],
    },
    {
      company: 'Spółdzielnia Na Skarpie',
      url: 'https://ksmnaskarpie.pl',
      logo: naSkarpie,
      businessCards: false,
      posters: true,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Leszek Ślipek',
          position: 'Prezes',
          phone: '',
          email: '',
        },
        {
          name: 'Beata Biernacka',
          position: 'Kierownik',
          phone: '506 382 498',
          email: '',
        },
        {
          name: 'Sekretariat',
          position: '',
          phone: '94 347 66 58',
          email: '',
        },
      ],
    },
    {
      company: 'Koci miętka - Kocia Kawiarnia',
      url: 'https://www.facebook.com/KociMietkaKoszalin/?locale=pl_PL',
      logo: kociaKawiarnia,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: '',
          position: 'Właściciel',
          phone: '537 178 947',
          email: 'kociakwiarniakoszalin@gmail.com',
        },
      ],
    },
    {
      company: 'Kawiarnia Cafe Mondo Rynek Staromiejski',
      url: 'https://cafemondo.pl',
      logo: cafeMondo,
      businessCards: true,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Małgorzata Przekurat',
          position: 'Właściciel',
          phone: '601 658 404',
          email: '',
        },
      ],
    },
    {
      company: 'Pizzeria Uno',
      url: 'https://unokoszalin.goorder.pl',
      logo: uno,
      businessCards: true,
      posters: true,
      discount: '10% na kod planszowki10',
      descriptionDiscount: 'Zniżka na jedzenie',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Dawid Promiński',
          position: 'Właściciel',
          phone: '510 654 800',
          email: 'dawidprominski@gmail.com',
        },
      ],
    },
    {
      company: 'Krowa Burger',
      url: 'https://krowaburger.goorder.pl',
      logo: krowa,
      businessCards: true,
      posters: true,
      discount: '10% na kod planszowki10',
      descriptionDiscount: 'Zniżka na jedzenie',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Dawid Promiński',
          position: 'Właściciel',
          phone: '510 654 800',
          email: 'dawidprominski@gmail.com',
        },
      ],
    },
    {
      company: 'Aleee Kawa',
      url: 'https://www.facebook.com/profile.php?id=100088390064592',
      logo: aleeeKawa,
      businessCards: false,
      posters: false,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na wszystkie kawy',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: '',
          position: 'Właściciel',
          phone: '691 041 991',
          email: 'aleeekawa@gmail.com',
        },
      ],
    },
    {
      company: 'AKM Finanse i Ubezpieczenia',
      url: 'https://akm-finanse.pl',
      logo: akm,
      businessCards: false,
      posters: false,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na OC i OC + AC',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Agnieszka Morawiak',
          position: 'Właściciel',
          phone: '722 392 983',
          email: 'agnieszka.morawiak@ovb.com.pl',
        },
      ],
    },
    {
      company: 'Barberian Ink',
      url: 'https://booksy.com/pl-pl/119126_barberian-ink_barber-shop_19501_koszalin',
      logo: barberianInk,
      businessCards: true,
      posters: false,
      discount: '10% - dodaj hasło planszowki10 na boosky',
      descriptionDiscount: 'Zniżka na wszystkie usługi',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Dawid Promiński',
          position: 'Właściciel',
          phone: '510 654 800',
          email: 'dawidprominski@gmail.com',
        },
      ],
    },
    {
      company: 'CliniQ',
      url: 'https://cliniq.pl',
      logo: cliniq,
      businessCards: false,
      posters: false,
      discount: '10% - na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na pierwszy scaling',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Kasia Nolik',
          position: 'Stomatolog',
          phone: '534 057 064',
          email: '',
        },
      ],
    },
    {
      company: 'Wyszkoleni',
      url: 'https://wyszkoleni.edu.pl',
      logo: wyszkoleni,
      businessCards: false,
      posters: false,
      discount: 'Zniżka na hasło Planszówki Koszalin',
      descriptionDiscount: 'Darmowa sesja rozwojowa',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Bartosz Łańcucki',
          position: 'Właściciel',
          phone: '512 222 312',
          email: 'kontakt@wyszkoleni.edu.pl',
        },
      ],
    },
    {
      company: 'BAM Design',
      url: 'https://www.instagram.com/bamdesign.art',
      logo: bam,
      businessCards: false,
      posters: false,
      discount: '15% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na wszystkie usługi',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Kamila Andrzejewska',
          position: 'Właściciel',
          phone: '530 910 711',
          email: 'bamdesign90@gmail.com',
        },
      ],
    },
    {
      company: 'Comics Club',
      url: 'https://www.facebook.com/ComicsClubKoszalin',
      logo: comicsClub,
      businessCards: false,
      posters: false,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na cały alkohol',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Sławomir Mastyjan',
          position: 'Właściciel',
          phone: '721 238 595',
          email: 'interpol1@wp.pl',
        },
      ],
    },
    {
      company: 'Complex Finance',
      url: 'https://complexfinance.pl',
      logo: complexFinance,
      businessCards: false,
      posters: false,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Pierwsza wykupiona usługa',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Agnieszka Bochnia',
          position: 'Właściciel',
          phone: '660 478 005',
          email: 'biuro@complexfinance.pl',
        },
      ],
    },
    {
      company: 'Taro Systems',
      url: 'https://tarosystems.pl',
      logo: taro,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Arkadiusz Martyna',
          position: 'Właściciel',
          phone: '507 693 328',
          email: 'arkadiusz.martyna@tarosystems.pl',
        },
      ],
    },
    {
      company: 'Majki Tattoo',
      url: 'https://www.facebook.com/profile.php?id=100092004222636',
      logo: tattoo,
      businessCards: false,
      posters: false,
      discount: '20% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na wszystkie usługi',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Mateusz Mikliński',
          position: 'Właściciel',
          phone: '783 835 545',
          email: 'mtts.koszalin@gmail.com',
        },
      ],
    },
    {
      company: 'Miejski Zakład Komunikacji Koszalin',
      url: 'https://www.koszalin.pl',
      logo: Mzk,
      businessCards: false,
      posters: true,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Karolina Hering',
          position: 'Referent ds. Marketingu',
          phone: '601 887 457',
          email: 'marketing@mzk.koszalin.pl',
        },
      ],
    },
    {
      company: 'CK 105 Koszalin',
      url: 'https://ck105.koszalin.pl',
      logo: ck105,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Marka Grzegorczyk',
          position: 'Marketing',
          phone: '607 882 438',
          email: 'marta.grzegorczyk@ck105.koszalin.pl',
        },
      ],
    },
    {
      company: 'Centrum Informacji Kulturalnej i Turystycznej Koszalin',
      url: 'https://cikit.koszalin.pl',
      logo: centrumInformacjiTurystycznej,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Maria Lewandowska',
          position: 'Specjalista Promocji i Marketingu',
          phone: '605 180 556',
          email: 'rcit.koszalin@gmail.com',
        },
      ],
    },
    {
      company: 'Rada Osiedla Śródmieście',
      url: 'https://www.facebook.com/profile.php?id=100084901999764',
      logo: radaOsSrodmiescie,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Katarzyna Krzyżanowska',
          position: 'Radna',
          phone: '602 486 690',
          email: 'ro.srodmiescie@outlook.com',
        },
      ],
    },
    {
      company: 'Koszalińska Biblioteka Publiczna',
      url: 'https://www.biblioteka.koszalin.pl',
      logo: Biblioteka,
      businessCards: false,
      posters: true,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Dariusz Pawlikowski',
          position: 'Prezes',
          phone: '606 351 144',
          email: '',
        },
        {
          name: 'Katarzyna Bielecka',
          position: 'Bibliotekarka',
          phone: '668 192 090',
          email: 'mediateka@biblioteka.koszalin.pl',
        },
      ],
    },
    {
      company: 'Rok Koszalińskiej Kultury',
      url: 'https://www.koszalin.pl/pl/page/rok-koszalinskiej-kultury',
      logo: Kultura,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Katarzyna Bielecka',
          position: 'Bibliotekarka',
          phone: '668 192 090',
          email: 'mediateka@biblioteka.koszalin.pl',
        },
      ],
    },
    {
      company: 'Muzeum w Koszalinie',
      url: 'http://www.muzeum.koszalin.pl',
      logo: muzeum,
      businessCards: false,
      posters: true,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Arleta Wagner',
          position: 'Sekretariat',
          phone: '94 343 20 11',
          email: 'sekretariat@muzeum.koszalin.pl',
        },
        {
          name: 'Zygmunt Kalinowski',
          position: 'Dyrektor',
          phone: '',
          email: 'sekretariat@muzeum.koszalin.pl',
        },
      ],
    },
    {
      company: 'Rada Osiedla Unii Europejskiej',
      url: 'http://osiedle-unii.eu',
      logo: Unia,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Adam Bochra',
          position: 'Radny',
          phone: '601 567 675',
          email: 'radaosiedla@osiedle-unii.eu',
        },
      ],
    },
    {
      company: 'Stowarzyszenie Mama w Mieście Koszalin',
      url: 'https://www.mwmk.org.pl',
      logo: Mwmk,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Joanna Kamińska',
          position: 'Członek Zarządu',
          phone: '513 046 524',
          email: 'joanna84kaminska@gmail.com',
        },
      ],
    },
    {
      company: 'Rada Osiedla Raduszka',
      url: 'https://www.facebook.com/OsiedleRaduszka',
      logo: Raduszka,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Marek Gutowski',
          position: 'Radny',
          phone: '601 451 027',
          email: '',
        },
      ],
    },
    {
      company: 'Pracownia Pozarządowa',
      url: 'https://pracowniapozarzadowa.pl',
      logo: PracowniaPozarzadowa,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Olga Antkowiak',
          position: 'Biuro',
          phone: '94 340 35 23',
          email: 'biuro@pracowniapozarzadowa.pl',
        },
      ],
    },
    {
      company: 'Restauracja Green',
      url: 'https://greenkoszalin.pl',
      logo: Green,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Dawid Promiński',
          position: 'Właściciel',
          phone: '510 654 800',
          email: 'dawidprominski@gmail.com',
        },
      ],
    },
    {
      company: 'Chyłki Popcorn & Wata Cukrowa',
      url: 'https://www.facebook.com/profile.php?id=100036868103704',
      logo: chylki,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Sylwia Chyła',
          position: 'Właściciel',
          phone: '798 744 601',
          email: 'noga-sylwia@wp.pl',
        },
        {
          name: 'Daniel Chyła',
          position: 'Właściciel',
          phone: '730 051 600',
          email: '',
        },
      ],
    },
    {
      company: 'Centrum Usług Społecznych w Koszalinie',
      url: 'https://cuskoszalin.eu',
      logo: cus,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Beata Szubert',
          position: 'Organizator Społeczności Lokalnej',
          phone: '515 806 210',
          email: 'b.szubert@cuskoszalin.eu',
        },
      ],
    },
    {
      company: 'Hufiec Ziemi Koszalińskiej',
      url: 'https://koszalin.zhp.pl',
      logo: hufiec,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Anna Kuternoga',
          position: 'Właściciel',
          phone: '602 595 296',
          email: '',
        },
        {
          name: 'Szymon Prusiński',
          position: '',
          phone: '789 415 387',
          email: '',
        },
      ],
    },
    {
      company: 'Fundacja Razem Raźniej',
      url: 'https://www.facebook.com/profile.php?id=100084494379920',
      logo: razemRazniej,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Sylwia Chyła',
          position: 'Właściciel',
          phone: '798 744 601',
          email: 'noga-sylwia@wp.pl',
        },
      ],
    },
    {
      company: 'Aqua Projekt',
      url: '',
      logo: Aqua,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Bartosz Maciejewski',
          position: 'Właściciel',
          phone: '668 822 926',
          email: 'aquaprojekt@wp.pl',
        },
      ],
    },
    {
      company: 'Galeria Emka',
      url: 'https://www.emka.pl',
      logo: emka,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Jacek Sikorski',
          position: 'Dyrektor',
          phone: '',
          email: 'jacek.sikorski@emka.pl',
        },
        {
          name: 'Zuzanna Giersz',
          position: 'Dział marketingu',
          phone: '609 532 564',
          email: '',
        },
      ],
    },
    {
      company: 'Sklep z grami planszowymi Zabawkarski',
      url: 'https://www.facebook.com/ZABAWKARSKI',
      logo: Zabawkarski,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Hubert Bielicki',
          position: 'Właściciel',
          phone: '94 342 20 75',
          email: 'hubert.bielicki97@gmail.com',
        },
      ],
    },
    {
      company: 'Pałac Młodzieży Koszalin',
      url: 'https://pm.koszalin.pl',
      logo: PalacMlodziezy,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Monika Kalkowska',
          position: 'Dyrektor',
          phone: '505 389 197',
          email: '',
        },
        {
          name: 'Katarzyna Podlaszczak',
          position: 'Kierownik Działu Imprez',
          phone: '505 214 212',
          email: 'katarzyna_sron@wp.pl',
        },
      ],
    },
    {
      company: 'Zdolne dzieciaki',
      url: 'https://www.zdolnedzieciaki.pl',
      logo: zdolneDzieciaki,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Mirosław Strucki',
          position: 'Właściciel',
          phone: '694 196 039',
          email: 'miroslawstrucki@gmail.com',
        },
      ],
    },
    {
      company: 'Osiedle cyrkonii',
      url: 'https://osiedlecyrkonii.pl',
      logo: osiedleCyrkonii,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Mirosław Strucki',
          position: 'Właściciel',
          phone: '694 196 039',
          email: 'miroslawstrucki@gmail.com',
        },
      ],
    },
    {
      company: 'SML Szkoła Językowa',
      url: 'https://www.sml.edu.pl',
      logo: smlSzkoła,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Mirosław Strucki',
          position: 'Właściciel',
          phone: '694 196 039',
          email: 'miroslawstrucki@gmail.com',
        },
      ],
    },
    {
      company: 'Tłumaczenia Koszalin',
      url: 'https://tlumaczeniakoszalin.pl',
      logo: smlTlumaczenia,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Mirosław Strucki',
          position: 'Właściciel',
          phone: '694 196 039',
          email: 'miroslawstrucki@gmail.com',
        },
      ],
    },
    {
      company: 'Pe Skin Med',
      url: 'https://www.peskinmed.pl',
      logo: pemiskin,
      businessCards: false,
      posters: false,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na masaże manualne',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Patrycja',
          position: 'Właściciel',
          phone: '508 805 352',
          email: 'peskinmed@gmail.com',
        },
      ],
    },
    {
      company: 'BMP',
      url: 'https://bmp.edu.pl',
      logo: bmp,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Bartłomiej Łańcucki',
          position: 'Właściciel',
          phone: '512 222 312',
          email: 'kontakt@bmp.edu.pl',
        },
      ],
    },
    {
      company: 'La Femme',
      url: 'https://booksy.com/pl-pl/210987_la-femme-koszalin_fryzjer_19501_koszalin',
      logo: laFemme,
      businessCards: false,
      posters: false,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na wszystkie usługi',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Bartłomiej Łańcucki',
          position: 'Właściciel',
          phone: '512 222 312',
          email: 'kontakt@bmp.edu.pl',
        },
      ],
    },
    {
      company: 'Poka Logo',
      url: 'https://pokalogo.pl/',
      logo: pokaLogo,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Joanna Adamczyk',
          position: 'Właściciel',
          phone: '502 967 698',
          email: 'biuro@pokalogo.pl',
        },
      ],
    },
  ],
  globalCompanies: [
    {
      company: 'Rehactive',
      url: 'https://rehactive.com.pl',
      logo: rehactive,
      businessCards: false,
      posters: false,
      discount: 'Zniżka na pierwszą wizytę 60 min',
      descriptionDiscount: '20% na hasło Planszówki Koszalin',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Dawid Besztak',
          position: 'Właściciel',
          phone: '530 547 289',
          email: 'kontakt@rehactive.pl',
        },
      ],
    },
    {
      company: 'Klub Żeglarski Bałtyk',
      url: 'https://marinabaltyk.pl',
      logo: Baltyk,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Bartosz Maciejewski',
          position: 'Właściciel',
          phone: '668 822 926',
          email: 'aquaprojekt@wp.pl',
        },
      ],
    },
    {
      company: 'Prezentowo',
      url: 'https://prezentowo.net',
      logo: Prezentowo,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Paulina Jeżewska',
          position: 'Marketing',
          phone: '604 111 581',
          email: 'paulinajezewskaa@gmail.com',
        },
      ],
    },
    {
      company: 'Luxury Butique',
      url: 'https://luxurybutique.pl',
      logo: LuxuryButique,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Paulina Jeżewska',
          position: 'Właściciel',
          phone: '604 111 581',
          email: 'paulinajezewskaa@gmail.com',
        },
      ],
    },
    {
      company: 'Medical Sprzęt Medyczny',
      url: 'https://medical.szczecin.pl/',
      logo: medical,
      businessCards: true,
      posters: false,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Produkty nierefundowane z NFZ',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Przemysław Szymański',
          position: 'Właściciel',
          phone: '601 823 774',
          email: 'szymanskiprzemciu@gmail.com',
        },
      ],
    },
    {
      company: 'Drukarnia Kadruk',
      url: 'https://kadruk.pl',
      logo: Kadruk,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Mariusz Kotarski',
          position: 'Właściciel',
          phone: '609 031 062',
          email: 'kadruk@kadruk.com.pl',
        },
        {
          name: 'Maciej Kowalik',
          position: 'Dyrektor',
          phone: '785 117 035',
          email: 'kadruk@kadruk.com.pl',
        },
      ],
    },
    {
      company: 'Fotograf Malowanie Światłem',
      url: 'https://www.facebook.com/malowanieswiatlemms',
      logo: MalowanieSwiatlem,
      businessCards: false,
      posters: false,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na wszystkie usługi',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Marysia Szymańska',
          position: 'Właściciel',
          phone: '516 411 184',
          email: 'marysiasz6@gmail.com',
        },
      ],
    },
    {
      company: 'Fundacja Lifesurfers',
      url: 'https://lifesurfers.pl',
      logo: lifesurfers,
      businessCards: false,
      posters: false,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Przemysław Szymański',
          position: 'Właściciel',
          phone: '500 377 540',
          email: 'fundacja@lifesurfers.pl',
        },
      ],
    },
    {
      company: 'Wesołe Pierniki',
      url: 'https://www.facebook.com/wesolepiernikiujustyny',
      logo: wesolePierniki,
      businessCards: false,
      posters: false,
      discount: '10% na hasło Planszówki Koszalin',
      descriptionDiscount: 'Zniżka na ręczne pierniki',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Justyna Wójewska',
          position: 'Właściciel',
          phone: '669 260 371',
          email: 'justynawujewska291@gmail.com',
        },
      ],
    },
    {
      company: 'Kolegobrzeg',
      url: 'https://kolegobrzeg.pl',
      logo: kolegobrzeg,
      businessCards: false,
      posters: false,
      discount: '20% na bilet wstępu',
      descriptionDiscount: '10% na asortyment',
      visibleDiscountUsers: true,
      contacts: [
        {
          name: 'Jakub Pawlak',
          position: 'Właściciel',
          phone: '518 585 050',
          email: 'info@kolegobrzeg.pl',
        },
      ],
    },
    {
      company: 'Kluby gier planszowych',
      url: 'https://klubygierplanszowych.pl',
      logo: klubyGierPlanszowych,
      discount: '',
      descriptionDiscount: '',
      visibleDiscountUsers: false,
      contacts: [
        {
          name: 'Marcin Szrama',
          position: 'Właściciel',
          phone: '',
          email: 'pomimo.zagramy@gmail.com ',
        }
      ],
    },
  ],
}
